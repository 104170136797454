<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Projects</h2>
        <div v-if="projects.length > 0">
            <v-card
            elevation="2"
            outlined
            class="mb-3"
            v-for="project in projects" v-bind:key="project.id"
            >
            <v-card-title style="padding-bottom:0px">
                <v-row>
                    <v-col cols=9><h4 class="primary--text">{{ project.name }}</h4></v-col>
                    <v-col cols=3><span class="primary--text">{{ getTaskProgress(project) }}%</span></v-col>
                </v-row>
            </v-card-title>
            <v-card-text style="padding-bottom:0px;">
                <h4 class="primary--text">{{ project.project_code }}</h4>
            </v-card-text>
            <v-card-actions>
                <v-row>
                <v-col cols=6><v-btn style="text-transform: capitalize;width:100%;" @click="openSummary(project)">Project Summary</v-btn></v-col>
                <v-col cols=6><v-btn style="text-transform: capitalize;width:100%;" class="primary float-right" @click="openTask(project)">Project Tasks</v-btn></v-col>
                </v-row>
            </v-card-actions>
            </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No projects found</v-alert>
            <v-btn small style="text-transform: capitalize;" class="primary" @click="refreshProjects">Refresh</v-btn>
        </div>
    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
export default {
    name:'Project',
    computed: {
        ... mapGetters ( {projects : "getAllProjects"})
    },
    methods: {
        ... mapActions (['fetchAllProjects']),
        openSummary (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('/projects/summary')
        },
        openTask (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('/projects/task')
        },
        refreshProjects () {
            let user_p = JSON.parse(localStorage.getItem('user'))
            this.fetchAllProjects(user_p.id)
            location.reload()
        },
        getTaskProgress (project) {
            if(project.tasks.length > 0){
                let counter = 0;
                project.tasks.forEach(element => {
                    if(element.status == "complete"){
                        counter = counter + 1
                    }
                });
                return Math.round((counter/project.tasks.length) * 100);
            }else{
                return 0;
            }
        }
    },
    mounted () {
        let user = JSON.parse(localStorage.getItem('user'))
        
        if(user == null){
            this.$router.push('/')
        }else{
            if(user.project != 1){
                this.$router.push('/home')
            } else {
                this.fetchAllProjects(user.id)
            }
        }
    }

}
</script>